<template>
  <!--<v-btn color="blue-grey" @click="create" class="white--text">
    <v-icon>{{ icon }}</v-icon>
    {{ title }}
  </v-btn>-->
  <div color="blue-grey">
    <v-btn color="red darken-1" text @click="reject">Close</v-btn>
    <v-btn
      color="primary darken-1"
      :disabled="!valid"
      text
      @click="add()"
      :loading="loading"
    >
      {{ title }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'DialogButtons',
  props: {
    save: { type: Function, required: true },
    close: { type: Function, required: true },
    loading: { type: Boolean, required: false },
    valid: { type: Boolean, required: true },
    title: { type: String, required: true },
  },

  data() {
    return {
      snackbar: {},
    }
  },

  methods: {
    add() {
      this.save()
    },
    reject() {
      this.close()
    },
  },

  created() {},
}
</script>
